import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import packageJson from '../package.json';

const generateErrorBlock = () => {
  const errorBlock = document.createElement('div');

  errorBlock.style.position = 'fixed';
  errorBlock.style.top = '50%';
  errorBlock.style.left = '50%';
  errorBlock.style.transform = 'translate(-50%, -50%)';
  errorBlock.style.width = '90%';
  errorBlock.style.padding = '10px';
  errorBlock.style.color = '#000';
  errorBlock.style.backgroundColor = '#fff';
  errorBlock.style.fontSize = '14px';
  errorBlock.style.lineHeight = '16px';
  errorBlock.style.border = '1px solid #E3E6EB';
  errorBlock.style.borderRadius = '8px';
  errorBlock.style.boxShadow = '0 4px 12px 0 rgba(0, 0, 0, 0.25)';
  errorBlock.textContent = "This add-in won't run in your version of Office. Please upgrade either to perpetual Office 2021 (or later) or to a Microsoft 365 account.";

  document.body.appendChild(errorBlock);
};

console.warn(`Add-in version: ${packageJson.version}`);

Office.initialize = () => {
  Office.onReady(() => {
    if (navigator.userAgent.indexOf("Trident") !== -1 || navigator.userAgent.indexOf("Edge") !== -1) {
      generateErrorBlock();
    } else {
      platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
    }
  });
}
